@import "./mixin";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
:root {
  --color-page-background: #{$dark_color};
  --color-text: #{$white_color};
  --theme-color: #{$theme_color};
  --theme-second-color: #{$theme_second_color};
  --theme-third-color: #{$theme_third_color};
  --white-color: #{$white_color};
  --black-color: #{$dark_color};
  --border: #{$dark_color};
  --font-family-poppins: "Poppins", sans-serif;
}
body {
  margin: 0;
  background: var(--white-color);
  color: var(--black-color);
  -webkit-text-size-adjust: $w_100;
  -ms-text-size-adjust: none;
  font: 16px/25px var(--font-family-poppins);
  overflow-x: $hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-break: break-word;
  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.5;
  font-family: var(--font-family-poppins);
}
.w-100 {
  width: $w_100;
}
.max-width {
  @include max_width;
}
.list-style-none {
  @include list_style_none;
}
.text-break {
  overflow-wrap: break-word;
}
.d-block {
  display: $d_block;
}
.d-none {
  display: $d_none;
}
.flex {
  display: $flex;
}
.flex-wrap {
  flex-wrap: wrap;
}
.items-center {
  align-items: center;
}
.items-start {
  align-items: flex-start;
}
.items-end {
  align-items: flex-end;
}
.content-justify-center {
  justify-content: center;
}
.content-justify-start {
  justify-content: flex-start;
}
.content-justify-end {
  justify-content: flex-end;
}
.column-direction {
  flex-direction: column;
}
.row-direction {
  flex-direction: row;
}
.ml-auto {
  margin-left: auto !important;
}
.mr-auto {
  margin-right: auto;
}
.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.m-0 {
  margin: 0;
}
.no-padding {
  padding: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
input[type="submit"],
button {
  cursor: pointer;
}
img {
  vertical-align: top;
  border-style: $d_none;
}
button:focus,
textarea:focus,
input:focus,
select {
  outline: $d_none;
}
textarea,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="number"],
input[type="date"],
select {
  color: var(--color-text);
  font-family: var(--font-family-poppins);
}
input[type="date"]::-webkit-calendar-picker-indicator {
  background: $transparent;
  color: $transparent;
  cursor: pointer;
  height: auto;
  @include position_abs_left;
  right: 0;
  bottom: 0;
  width: auto;
}
*:focus {
  outline: $d_none;
}
.transition {
  @include transition;
}
#wrapper {
  width: $w_100;
  position: $position_rel;
  overflow: $hidden;
}
.container {
  width: $w_100;
  max-width: 1140px;
  padding: 0 15px;
  margin: $margin_auto;
}
.container.fluid {
  max-width: $d_none;
  padding: 0 40px;
  @media (max-width: $breakpoint_tablet) {
    padding: 0 15px;
  }
}
.container:after {
  display: block;
  clear: both;
  content: "";
}
.position_abs_right {
  @include position_abs_right;
}
.position_abs_left {
  @include position_abs_left;
}
.relative {
  position: relative;
}
.row {
  margin: 0 -15px;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
  padding: 0 15px;
  @media (max-width: 800px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
  padding: 0 15px;
}
.col-2 {
  flex: 0 0 20%;
  max-width: 20%;
  padding: 0 15px;
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}
.form-control {
  @include flex;
  height: 50px;
  background: $transparent;
  border: none;
  color: var(--white-color);
  font-size: 15px;
  &.error {
    border: 1px solid #ff2d55 !important;
    &:focus {
      border-color: #ff2d55 !important;
    }
  }
}
.form-input {
  border-radius: 0.3rem;
  width: 100%;
  height: 63px;
  background: #ffffff;
  box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
  border: none;
  padding-left: 20px;
  font-size: 16px;
  color: var(--black-color) !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  &.error {
    border: 1px solid #ff2d55 !important;
    &:focus {
      border-color: #ff2d55 !important;
    }
  }
  @media (max-width: $breakpoint_laptop) {
    font-size: 14px;
    height: 54px;
  }
  @include transition;
  &:focus {
    border-color: #555 !important;
  }
}
.form-input::-ms-expand {
  display: none;
}
.select-container {
  position: relative;
}
.select-container::after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  top: 55%;
  right: 15px;
  transform: translateY(-50%);
  pointer-events: none;
}
.panel-inner table .form-input {
  box-shadow: none;
  width: 120px;
  height: 40px;
  border: 1px solid #ccc;
}
textarea.form-input {
  height: 241px;
  padding-top: 15px;
}
.width-50 {
  width: 50% !important;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100% !important;
  }
}
table {
  background-color: transparent;
  max-width: 100%;
  border-collapse: collapse;
}
th {
  text-align: left;
}
table {
  width: 100%;
}
table > thead > tr > th,
table > tbody > tr > th,
table > tfoot > tr > th,
table > thead > tr > td,
table > tbody > tr > td,
table > tfoot > tr > td {
  //border: 1px solid #666666;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
}
table thead tr th {
  border-top: 1px solid #666666;
  text-align: center;
  text-transform: capitalize;
}
.table > caption + thead > tr:first-child > th,
.table > colgroup + thead > tr:first-child > th,
.table > thead:first-child > tr:first-child > th,
.table > caption + thead > tr:first-child > td,
.table > colgroup + thead > tr:first-child > td,
.table > thead:first-child > tr:first-child > td {
  border-top: 1px solid #666666;
}
table > thead > tr > th {
  border-bottom: 2px solid #666666;
  vertical-align: middle;
}
table > caption + thead > tr:first-child > th,
table > colgroup + thead > tr:first-child > th,
table > thead:first-child > tr:first-child > th,
table > caption + thead > tr:first-child > td,
table > colgroup + thead > tr:first-child > td,
table > thead:first-child > tr:first-child > td {
  border-bottom: 0 none;
}
table > tbody + tbody {
  border-top: 2px solid #666666;
}
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 19px 10px;
  @media (max-width: $breakpoint_laptop) {
    padding: 11px 10px;
  }
}
.main-trail-head {
  padding: 50px 0;
}
#root {
  overflow: hidden;
}
.css-13cymwt-control,
.css-t3ipsp-control {
  height: 63px;
  border-radius: 0.3rem !important;
  background: transparent !important;
  border-color: #1d2544 !important;
  box-shadow: none !important;
}
.css-166bipr-Input,
.css-qbdosj-Input,
.css-1jqq78o-placeholder,
.css-1dimb5e-singleValue {
  padding-left: 45px;
  color: #1d2544 !important;
  font-size: 16px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1xc3v61-indicatorContainer {
  color: #1d2544 !important;
}
.css-1fdsijx-ValueContainer {
  padding: 2px 5px !important;
}
.panel-inner {
  @media (max-width: $breakpoint_laptop) {
    .css-13cymwt-control,
    .css-t3ipsp-control {
      height: 54px;
    }
    .css-166bipr-Input,
    .css-qbdosj-Input,
    .css-1jqq78o-placeholder,
    .css-1dimb5e-singleValue {
      font-size: 14px !important;
    }
  }
  .css-166bipr-Input,
  .css-qbdosj-Input,
  .css-1jqq78o-placeholder,
  .css-1dimb5e-singleValue {
    padding-left: 15px;
  }
  .css-13cymwt-control,
  .css-t3ipsp-control {
    border-color: #dddddd !important;
  }
}
