@import "../../../assets/scss/mixin";

.banner-wrapper {
  height: 60vh;
  @media (max-width: $breakpoint_mobile_landscape) {
    height: 100%;
  }
}

.image-container {
  position: relative;
  height: 100%;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.671); /* Black overlay with 50% opacity */
  z-index: 1; /* Ensure the overlay appears above the image */
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
    margin-bottom: 30px;
    @media (max-width: $breakpoint_mobile_landscape) {
      font-size: 20px;
      margin-bottom: 5px;
      margin-top: 30px;
    }
  }
  span {
    color: white;
    font-size: 18px;
    text-align: center;
    @media (max-width: $breakpoint_mobile_landscape) {
      font-size: 14px;
      display: none;
    }
  }
  .buy-car-btn {
    padding: 10px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border: 1px solid var(--theme-second-color);
    color: var(--white-color);
    border-radius: 10px;
    font-size: 20px;
    margin: 20px 0 0 0;
    backdrop-filter: blur(10px);
    &:hover {
      background-color: rgba(0, 0, 0, 0.5);
    }

    @media (max-width: $breakpoint_mobile_landscape) {
      padding: 10px 20px;
    }
  }

  z-index: 2;
}
