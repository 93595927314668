@import "../../assets/scss/mixin";

.contact-us-wrapper {
  width: 100%;
  h3 {
    color: var(--theme-color);
    font-weight: 600;
    font-size: 30px;
    text-align: center;
    margin-bottom: 0px;
    padding: 40px 0px 0px 0px;
  }
}

.contact-us-container {
  padding: 40px 80px 80px 80px;
  display: flex;
  flex-direction: row;
  //   align-items: center;
  justify-content: center;
  width: 65rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    flex-direction: column;
    padding: 40px 20px;
  }
}

.contact-us-content {
  width: 60%;
  border: 1px solid var(--theme-color);
  border-radius: 10px;
  padding: 40px 20px;
  background-color: var(--theme-color);
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
}

.contact-us-image {
  width: 40%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
}

.input-field {
  height: 50px;
  border-radius: 10px;
  margin: 0 0 20px 0;
}

.banner-btn {
  width: 100%;
  height: 50px;
  background-color: var(--theme-second-color);
  color: var(--white-color);
  border-radius: 10px;
  border: none;
  font-size: 20px;
}
.banner-btn:hover {
  background-color: black;
}

.contact-us-info {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  margin-left: 10%;
  @media (max-width: $breakpoint_mini_laptop) {
    margin: 10px 0;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 18px;
      margin: 0;
    }
  }
}

.map-container {
  margin-left: 10%;
  width: 100%;
  height: 100%;
  @media (max-width: $breakpoint_mini_laptop) {
    display: none;
  }
}

.fa-phone,
.fa-envelope,
.fa-map-marker {
  font-size: 40px;
  padding: 10px;
  color: var(--theme-color);
  @media (max-width: $breakpoint_mini_laptop) {
    font-size: 20px;
  }
}
