@import "../../assets/scss/mixin";
.modal-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: hidden;
  overflow-y: auto;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9;
  .modal-dialog {
    min-height: calc(100% - (1.75rem * 2));
    max-width: 500px;
    margin: 1.75rem auto;
    display: flex;
    padding: 0 10px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
    .modal-content {
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      .modal-body {
        flex: 1 1 auto;
        padding: 25px 30px 30px;
        @media (max-width: $breakpoint_tablet) {
          padding: 20px 15px;
        }
      }
    }
  }
}
.modal-forms {
  textarea {
    height: 150px;
  }
  h2 {
    margin: 0 0 25px;
    text-align: center;
    @media (max-width: $breakpoint_tablet) {
      margin: 0 0 20px;
      font-size: 21px;
    }
  }
  p {
    text-align: center;
    margin-bottom: 30px;
  }
  .banner-btn {
    margin: 0 5px;
  }
  ul {
    li {
      margin-bottom: 20px;
      .banner-btn {
        margin: 0 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
      .form-input {
        box-shadow: 2px 2px 9px rgb(0 0 0 / 12%);
      }
    }
  }
}

.home-video-container {
  position: relative;
  border: 1px solid var(--theme-second-color);
  border-radius: 5px;
  background-color: var(--white-color);
  display: flex;
  flex-direction: column;

  .form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h4 {
      text-align: center;
      font-size: 30px;
      margin: 20px 0 0 0;
    }
    h5 {
      text-align: center;
      font-size: 14px;
      margin: 0 0 20px 0;
    }

    .field-container {
      display: flex;
      .input-field {
        width: 250px;
        border: 1px solid var(--theme-second-color);
        height: 50px;
        border-radius: 30px 0 0 30px;
      }
      .banner-btn {
        width: 120px;
        height: 50px;
        background-color: var(--theme-color);
        color: var(--white-color);
        border-radius: 0 30px 30px 0;
      }
      .banner-btn:hover {
        background-color: var(--theme-second-color);
      }
    }

    h6 {
      text-align: center;
      font-size: 14px;
      margin: 0 0 40px 0;
      cursor: pointer;
    }
  }

  .image {
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      border-radius: 5px 5px 0 0;
    }
  }

  .cross-icon {
    position: absolute;
    right: 0;
    margin-top: -11px;
    margin-right: -10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: transform 0.3s ease;
  }
  .cross-icon:hover {
    transform: scale(1.2);
  }
  .video-player {
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
}

.Video-modal-dialog {
  min-height: calc(100% - (1.75rem * 2));
  max-width: 700px;
  margin: 1.75rem auto;
  display: flex;
  padding: 0 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  .modal-content {
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    width: 100%;
  }
}
