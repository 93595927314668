@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css");
body {
  margin-top: 79px;
}
.relative {
  position: relative;
}
.cusrom_css {
  background: #f00;
  padding: 49px;
}
.google_login {
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  gap: 20px;
  box-sizing: border-box;
  margin-bottom: 20px;
}
.google_login img {
  width: 25px;
}

.google_login:hover {
  background: var(--theme-color);
  color: #fff;
}

.for_small {
  display: block;
  float: right;
  text-decoration: underline;
  font-size: 15px;
}

.user_round_image {
  width: 124px;
  height: 124px;
  border-radius: 100%;
  border: 1px solid #3ec27e;
  object-fit: cover;
}

.pro_image_header_login {
  width: 32px;
  height: 32px;
  border-radius: 100%;
  border: 1px solid #3ec27e;
  object-fit: cover;
}

.outer_image {
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  z-index: 9999;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
}
.inner_image {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_inner_image {
  background: #fff;
  padding: 20px;
  width: 30%;
  border-radius: 5px;
  text-align: center;
}
.box_inner_image h4 {
  margin-top: 0px;
}

.no_car {
  width: 40%;
  margin: auto;
}
.new_car_button i {
  font-size: 45px;
  color: var(--theme-color);
  opacity: 0.8;
}
.new_car_button:hover {
  background: #fafafa;
}

.new_car_button {
  border: 1px solid #f0f0f0;
  padding: 30px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  gap: 15px;
  cursor: pointer;
}

.no_car_text {
  margin-bottom: 22px;
  text-align: center;
  color: #fa6868;
}

.new_car_add {
  border: 1px solid #f0f0f0;
  padding: 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  background: var(--theme-color);
  color: #fff;
  min-width: 150px;
  gap: 10px;
  text-transform: uppercase;
}
.new_car_add i {
  font-size: 22px;
}

.heading_make {
  text-transform: uppercase;
  font-family: arial;
  font-weight: 600;
  color: var(--theme-color);
  margin-bottom: 10px;
  margin-top: 15px;
}

.car_make_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.car_model_wrap .car_boxes_makes {
  flex-direction: row-reverse;
  width: 32%;
  justify-content: space-between;
}
.car_model_wrap .car_boxes_makes img {
  height: 80px;
  margin-bottom: 0;
}

.car_boxes_makes {
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  min-width: 175px;
}
.car_boxes_makes:hover {
  border: 1px solid var(--theme-color);
}
.car_boxes_makes img {
  height: 90px;
  margin-bottom: 10px;
}
.hybrid_electric {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 10px;
  font-family: arial;
  margin-top: 10px;
}
.hybrid_pill {
  background-color: #1cdce8;
  padding: 0px 10px;
  border-radius: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #fff;
}
.electric_pill {
  background-color: #38c498;
  padding: 0px 10px;
  border-radius: 50px;
  height: 20px;
  display: flex;
  align-items: center;
  color: #fff;
}
.car_boxes_makes.selected {
  border: 1px solid var(--theme-color);
  background: #fefefe;
}

.bar_selected {
  position: absolute;
  top: 1px;
  right: 3px;
  z-index: 9;
}
.bar_selected i {
  color: green;
  font-size: 25px;
}

.cancel_car_button {
  background: #ff8383;
  border: inherit;
  padding: 10px 18px;
  border-radius: 4px;
  color: #fff;
  width: 100px;
}
.cancel_car_button.gren {
  background: green;
  color: #fff;
}
.cancel_car_button.green.disabled {
  color: #fff;
}

.addNewCar-wrapper h4 p {
  font-size: 14px;
  font-weight: normal !important;
}

.trim_box_wrap {
}
.trim_box_outer {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
}
.heading_trim {
  font-size: 15px;
  font-weight: bold;
}
.des_trim {
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 20px;
}

.trim_wrapper_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1.5px solid #e1e1e1;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.name_trimer {
  font-size: 13px;
  font-weight: bold;
}
.choose_trim {
  background: var(--theme-color);
  color: #fff;
  padding: 11px 25px;
  border: inherit;
  border-radius: 100px;
  font-size: 13px;
}
.choose_trim:hover {
  background-color: var(--black-color);
}
.choose_trim.selected {
  background-color: tomato;
}

.bottom_left_engine {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.left_engine_data {
  width: 65%;
}
.bottom_left_engine .per_div {
  margin-top: 10px;
  font-size: 12px;
  line-height: 20px;
}
.bottom_left_engine .per_div span {
  color: grey;
  font-weight: 600;
}

.color_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}

.color_box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  width: 32%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.circle_check {
  width: 26px;
  height: 26px;
  border: 1px solid #949494;
  border-radius: 100%;
}

.lef_colo_box,
.left_bar_add {
  display: flex;
  align-items: center;
  gap: 17px;
}

.color_swatcher {
  width: 40px;
  height: 40px;
  border: inherit;
  border-radius: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.color_box.active {
  border: 1px solid var(--theme-color);
}
.color_box.active .circle_check {
  background: var(--theme-color);
}

.heading_additional {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: var(--theme-color);
  margin-bottom: 15px;
  cursor: pointer;
}

.additional_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #f0f0f0;
  padding: 10px;
  border-radius: 7px;
  cursor: pointer;
}
.additional_bar.active {
  border: 1px solid var(--theme-color);
}

.additional_bar.active .bar_check {
  background: var(--theme-color);
}
.bar_check {
  width: 18px;
  height: 18px;
  border: 1px solid #949494;
  border-radius: 5px;
}

.region_dropdown {
  float: left;
  padding: 9px;
  color: #202020;
  border-radius: 3px;
  width: 302px;
}

.cirlce-colorr {
  width: 16px;
  height: 16px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  gap: 9px;
}

.icon_archive {
  position: absolute;
  right: 7px;
  font-size: 22px;
  top: 5px;
  color: #ff6464;
  cursor: pointer;
}

.icon_archive:hover {
  color: red !important;
}

.popup_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}
.small_pop_text {
  line-height: 18px;
  font-size: 12px;
}

.car_into_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.optios_car {
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}

.box_price_pop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px dotted #f0f0f0;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.edit_icon_abs {
  position: absolute;
  left: 7px;
  font-size: 22px;
  top: 5px;
  color: #272727;
  cursor: pointer;
}
.edit_icon_abs:hover {
  color: orange;
}
.icon_show_ {
  position: absolute;
  right: 13px;
  top: 19px;
  font-size: 18px;
  cursor: pointer;
}

.regionsss_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  flex-wrap: wrap;
}
.regionsss_wrap .region_boxes {
  width: auto;
  border: 1.5px solid #f0f0f0;
  padding: 5px 15px;
  font-size: 12px;
  border-radius: 30px;
  cursor: pointer;
}
.regionsss_wrap .region_boxes.selected {
  background: var(--theme-color);
  color: white;
  border: 1.5px solid var(--theme-color);
}

.mob_center {
  text-align: center;
}
.mycars-main {
  min-height: calc(100vh - 30vh);
}

.dashboarddealer_space {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.new_leftsss {
  width: 30%;
}
.cars_left_dash_dealer {
  width: 70%;
}
.abs_price_right {
  color: var(--theme-color);
  position: absolute;
  right: 19px;
  font-weight: bold;
}
.pills_wrap_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}
.pills_user_cars {
  display: flex;
  align-items: center;
  gap: 6px;
  border: 1px solid #ccc;
  text-align: center;
  padding: 1px 10px;
  border-radius: 25px;
  font-size: 12px;
}

@media (max-width: 786px) {
  /* .type-options-container .type-option:hover {
        background-color: white !important;
        color: var(--black-color) !important;
    } */
  .wrapper_news {
    height: 221px !important;
  }
  .dashboarddealer_space {
    flex-direction: column;
  }
  .cars_left_dash_dealer,
  .new_leftsss {
    width: 100% !important;
  }
  .cars_Dealer_type .mycars-cards {
    width: 100% !important;
  }
  .dealer_Cars_mobile .car_boxes_makes {
    width: 20% !important;
    min-width: 100px !important;
    border-radius: 100px;
    height: 100px;
    padding: 10px;
  }
  .dealer_Cars_mobile .car_boxes_makes img {
    margin-top: 10px;
    height: 35px !important;
  }
  .dealer_Cars_mobile .car_boxes_makes div {
    font-size: 10px;
  }
  .dealer_Cars_mobile .hybrid_electric {
    display: none;
  }
  .mobiles_small,
  .mobiles_small a {
    font-size: 15px !important;
  }
  .multi-steps {
    display: flex !important;
    table-layout: none;
    width: 100%;
    padding-left: inherit;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .multi-steps > li {
    width: 33% !important;
  }
  .multi-steps > li:nth-child(3n + 3):after {
    background-color: white !important;
  }

  body {
    margin-top: 62px;
  }
  .box_inner_image,
  .no_car {
    width: 95%;
  }
  .mycars-wrapper,
  .addNewCar-wrapper {
    padding: 0 20px 40px !important;
  }
  .addNewCar-wrapper .addNewCar-heading-container,
  .trim_box_engine .trim_wrapper_inner {
    flex-direction: column;
  }
  .trim_box_wrap .buton_trim_sel {
    margin-top: 0;
  }
  .car_boxes_makes {
    width: 48%;
    min-width: auto;
  }
  .car_model_wrap .car_boxes_makes,
  .type-options-container .type-option,
  .left_engine_data,
  .color_box {
    width: 100% !important;
  }
  .car_model_wrap {
    margin-top: 0px;
  }
  .addNewCar-heading-container {
    margin-bottom: 30px;
  }
  .buton_trim_sel {
    margin-top: 20px !important;
  }

  .tabs_wrap,
  .notif_wor {
    flex-direction: column;
  }
  .tabs_wrap .tabs {
    width: 90% !important;
  }
  .custom_mar {
    width: 95% !important;
  }
  .form_inp_signup {
    width: 100% !important;
  }
  .off_trm_box {
    font-size: 9px !important;
  }
  .dp_flex {
    gap: 5px !important;
  }
}

/* 2nd .multi-step */

.multi-steps > li.is-active ~ li:before,
.multi-steps > li.is-active:before {
  content: counter(stepNum);
  font-family: inherit;
  font-weight: 700;
}

.multi-steps > li.is-active ~ li:after,
.multi-steps > li.is-active:after {
  background-color: #ededed;
}

.multi-steps {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding-left: inherit;
}

.multi-steps > li {
  counter-increment: stepNum;
  text-align: center;
  display: table-cell;
  position: relative;
  color: var(--theme-color);
  font-size: 12px;
  font-family: arial;
}

.multi-steps > li:before {
  content: "";
  content: "✓;";
  content: "𐀃";
  content: "𐀄";
  content: "✓";
  display: block;
  margin: 0 auto 4px;
  background-color: #fff;
  width: 36px;
  height: 36px;
  line-height: 34px;
  text-align: center;
  font-weight: bold;
  border-width: 2px;
  border-style: solid;
  border-color: var(--theme-color);
  border-radius: 50%;
}

.multi-steps > li:after {
  content: "";
  height: 2px;
  width: 100%;
  background-color: var(--theme-color);
  position: absolute;
  top: 20px;
  left: 50%;
  z-index: -1;
}

.multi-steps > li:last-child:after {
  display: none;
}

.multi-steps > li.is-active:before {
  background-color: #fff;
  border-color: tomato;
}

.multi-steps > li.is-active ~ li {
  color: #808080;
}

.multi-steps > li.is-active ~ li:before {
  background-color: #ededed;
  border-color: #ededed;
}

.custom_wrap_login {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-top: 30px;
}
.form_inp_signup {
  width: 48%;
}

.wd100 {
  width: 100% !important;
}
.small_Cars {
  border: 1px solid #f0f0f0 !important;
}
.small_Cars img {
  height: 45px;
}
.dashboard_wrp .car_boxes_makes {
  min-width: 145px;
}

.tabs_wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 23px;
}
.tabs_wrap .tabs {
  background: #fff;
  border: 1px solid #f0f0f0;
  padding: 10px 20px;
  border-radius: 5px 5px 0 0;
  width: auto;
  border-bottom: none;
  text-align: center;
  cursor: pointer;
}
.tabs_wrap .tabs.selected {
  background: var(--theme-color);
  color: #fff;
  text-align: center;
}

.trims_offer_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.off_trm_box {
  border: 1px solid #f0f0f0;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 12px;
  cursor: pointer;
}
.off_trm_box:hover {
  border: 1px solid var(--theme-color);
}
.selected {
  background: var(--theme-color);
  color: #fff;
}
.custom_mar {
  width: 50%;
  height: 90vh;
  overflow-y: auto;
}
.custom_mar h5 {
  text-align: left;
}
.color_round {
  width: 20px;
  height: 20px;
  border-radius: 100px;
}
.dp_flex {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cst_car {
  margin-top: 20%;
}

.gap_spac_bt {
  justify-content: space-between !important;
  gap: 20px;
  padding: 10px;
}

.gap_spac_bt .rjct_offer {
  background: rgb(251, 120, 120);
  border: red;
  padding: 15px;
  color: #fff;
  width: 30%;
  border-radius: 5px;
}

.gap_spac_bt .acc_off {
  background: var(--theme-color);
  border: var(--theme-color);
  padding: 15px;
  color: #fff;
  width: 70%;
  border-radius: 5px;
}
.gap_spac_bt .acc_off:hover {
  background: var(--black-color);
}

.total_count {
  width: 16px;
  display: flex;
  height: 16px;
  border-radius: 100px;
  background: var(--theme-color);
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;
  position: absolute;
  top: -11px;
  right: -6px;
}

.notif_wor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e7e7e7;
  padding: 10px 20px;
  border-radius: 10px;
  margin-bottom: 10px;
}
.notif_date {
  font-size: 12px;
}

.w50per {
  width: 90% !important;
}

.wrapper_news {
  height: 240px;
  overflow: auto;
}
.cars_Dealer_type {
  width: 100% !important;
}
.cars_Dealer_type .mycars-cards {
  width: 46%;
}

.news_box {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border: 1px solid var(--theme-color);
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
}
.news_box h5 {
  margin-bottom: 5px;
  margin-top: 0;
}

.pop_table {
  border: 1px solid #ccc;
}
.pop_table th {
  background: #f1f1f1;
  width: 30%;
  padding: 5px;
  text-align: center;
  border-bottom: 1px solid #ccc;
}
.pop_table td {
  background: #fff;
  width: 70%;
  padding: 5px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
