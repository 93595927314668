@import "../../assets/scss/mixin";
.sub-header {
  // background: url("../../assets/images/subheader.jpg") center fixed;
  background: linear-gradient(to bottom, #23503a, #173829);
  padding: 0 0;
  background-size: cover;
  border-radius: 0 0 0 120px;
  @media (max-width: $breakpoint_laptop) {
    padding: 0 0;
  }
  @media (max-width: $breakpoint_tablet) {
    padding: 0 0;
  }
  &.course-header {
    span {
      padding: 70px 0 0;
      display: block;
      color: #ffffff;
      text-align: center;
      font-size: 45px;
      margin: 0 0 25px;
      font-weight: 600;
    }
    h1 {
      padding: 0 0 70px;
      font-weight: 400;
    }
  }
  h1 {
    padding: 100px 0;
    color: #ffffff;
    margin: 0;
    text-align: center;
    font-size: 48px;
    @media (max-width: $breakpoint_laptop) {
      padding: 50px 0 60px;
      font-size: 32px;
    }
    @media (max-width: $breakpoint_tablet) {
      font-size: 20px;
      padding: 30px 0;
    }
  }
}
