@import "../../assets/scss/mixin";
.addNewCar-main {
  width: 100%;
}

.addNewCar-wrapper {
  width: 70rem;
  h4 {
    font-size: 30px;
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 20px;
    }
  }
  padding: 0px 0px 80px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 0 40px 40px 40px;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
    color: var(--white-color);
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 18px;
      text-align: center;
    }
  }

  .addNewCar-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .btn-container {
    display: flex;
    gap: 10px;
    padding-top: 40px;
  }

  .addNewCar-build-new-car-btn {
    width: 150px;
    height: 50px;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 10px;
    border: none;
    font-size: 18px;

    &:hover {
      background-color: var(--theme-second-color);
      border: none;
    }
  }

  .addNewCar-build-new-car-btn.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

// choosemake
.car-options-container {
  display: flex;
  flex-wrap: wrap;

  .car-option {
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid var(--theme-color);
    border-radius: 5px;

    &:hover {
      background-color: var(--theme-second-color);
      color: #fff;
    }

    &.selected {
      background-color: var(--theme-color);
      color: #fff;
    }
  }
}

// choosemodel
.model-options-container {
  display: flex;
  flex-wrap: wrap;

  .model-option {
    width: 49.1%;
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }

    img {
      width: 150px;
    }

    .name-container {
      display: flex;
      flex-direction: column;
    }

    &:hover {
      background-color: var(--theme-second-color);
      color: #fff;
    }

    &.selected {
      background-color: var(--theme-color);
      color: #fff;
    }
  }
}

// chooseType
.type-options-container {
  display: flex;
  flex-direction: column;
  .type-option {
    width: 300px;
    height: 60px;
    cursor: pointer;
    padding: 10px 20px;
    margin: 5px;
    border: 1px solid var(--theme-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 10px;

    input[type="radio"],
    input[type="checkbox"] {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      background-color: #007bff;
      border: 2px solid #007bff;

      &:checked {
        border-color: #fff;
        background-color: #fff;
      }
    }
    p {
      margin: 0;
      font-size: 16px;
      font-weight: 600;
    }

    img {
      width: 150px;
    }

    .name-container {
      display: flex;
      flex-direction: column;
    }

    // &:hover {
    //   background-color: var(--theme-second-color);
    //   color: #fff;
    // }

    &.selected {
      background-color: var(--theme-color) !important;
      color: #fff !important;
    }
  }
}
