@import "../../assets/scss/mixin";
.footer-wrapper {
  .footer-widget {
    // background: url("../../assets/images/footer-bg1.jpg") center;
    // background-color: #8aab9b;
    background: linear-gradient(to bottom, #23503a, #173829);
    padding: 40px 0 30px;
    @media (max-width: $breakpoint_mobile_portrait) {
      padding: 20px 0;
      .container {
        flex-wrap: wrap;
      }
    }
    .footer-logo {
      margin: 20px 0;
      @media (max-width: $breakpoint_mobile_portrait) {
        text-align: center;
        margin-top: 0;
        img {
          display: inline-block;
        }
      }
      img {
        width: 300px;
      }
    }
    .footer-info {
      width: 45%;
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 100%;
        margin-bottom: 20px;
      }
      p {
        font-size: 15px;
        color: var(--white-color);
        margin: 3px 0 0 0;
        font-weight: 500;
        @media (max-width: $breakpoint_mobile_portrait) {
          font-size: 15px;
        }
      }
    }
    .footer-links {
      width: 27.5%;
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 50%;
        align-items: flex-start;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h4 {
        font-size: 24px;
        margin: 0 0 24px;
        color: var(--white-color);
      }
      ul {
        li {
          line-height: 1;
          margin-bottom: 20px;
          @media (max-width: $breakpoint_laptop) {
            margin-bottom: 14px;
          }
          a {
            color: var(--white-color);
            line-height: 25px;
            position: $position_rel;
            padding-left: 29px;
            @include transition;
            @media (max-width: $breakpoint_mobile_landscape) {
              line-height: 10px;
            }
            &:before {
              content: "";
              position: $position_abs;
              left: 0;
              top: 4px;
              background-image: url("../../assets/images/active-dot.svg");
              width: 13px;
              height: 15px;
              background-size: 100% 100%;
              @include transition;
            }
            &:hover {
              color: var(--theme-second-color);
              &:before {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }

    .footer-links-2 {
      width: 27.5%;
      @media (max-width: $breakpoint_mobile_portrait) {
        width: 50%;
        align-items: flex-start;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      h4 {
        font-size: 24px;
        margin: 0 0 24px;
        color: var(--white-color);
      }
      ul {
        li {
          line-height: 1;
          margin-bottom: 20px;
          @media (max-width: $breakpoint_laptop) {
            margin-bottom: 14px;
          }
          a {
            color: var(--white-color);
            line-height: 25px;
            position: $position_rel;
            text-decoration: underline;
            text-decoration-color: var(--theme-second-color);
            text-underline-offset: 3px;
            @include transition;
            @media (max-width: $breakpoint_mobile_landscape) {
              line-height: 10px;
            }
            &:hover {
              text-decoration-color: var(--theme-third-color);
              color: var(--theme-second-color);
            }
          }
        }
      }
    }
  }

  .copyright {
    height: 60px;
    text-align: center;
    color: var(--white-color);
    p {
      margin: 0;
    }
    background: linear-gradient(to bottom, #23503a, #173829);
    @media (max-width: $breakpoint_laptop) {
      height: 36px;
    }
  }
}
.social-icons {
  padding-top: 25px;
  @media (max-width: $breakpoint_mobile_landscape) {
    justify-content: center;
  }
  li {
    margin-right: 30px;
    a {
      color: var(--white-color);
      opacity: 0.8;
      font-size: 20px;
      @include transition;
      &:hover {
        opacity: 1;
        transform: scale(1.2);
      }
    }
  }
}

.logo-footer-heading {
  font-size: 50px;
  color: var(--white-color);
  font-weight: 600;
  margin: 0 0 20px 0;
  @media (max-width: $breakpoint_mobile_landscape) {
    font-size: 30px;
  }
}

.contact-info-footer {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.logo {
  max-width: 350px;
  @include transition;
  img {
    width: 100%;
    max-width: 350px;
  }
  @media (max-width: $breakpoint_laptop) {
    max-width: 300px;
  }
  @media (max-width: $breakpoint_tablet) {
    max-width: 180px;
  }
}
