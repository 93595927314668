@import "../../assets/scss/mixin";

.faq-wrapper-main {
  background: linear-gradient(to bottom, #173829, #23503a);
  width: 100%;
}

.faq-wrapper {
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 0 120px 0 0;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 40px 20px;
  }
}

.faq-container {
  padding: 15px;
  width: 65rem;
  border: 1px solid #f0f0f0;
  margin: 0px 0px 10px 0px;
  border-radius: 10px;
  background-color: var(--theme-second-color);
  h2 {
    margin: 0;
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
}

.faq-container-color {
  background-color: #f8f8f8;
}

.fa-minus,
.fa-plus {
  background-color: var(--white-color);
  padding: 5px;
  border-radius: 50%;
}
