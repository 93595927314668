@import "../../../assets/scss/mixin";

.reviews-wrapper-main {
  width: 100%;
  background-color: var(--theme-color);
}

.reviews-wrapper {
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white-color);
  border-radius: 200px 0 0 0;
  @media (max-width: $breakpoint_mobile_landscape) {
    border-radius: 200px 0 0 0;
  }
  h4 {
    text-align: center;
    font-size: 30px;
  }
  img {
    margin-top: -50px;
    @media (max-width: $breakpoint_mobile_landscape) {
      width: 300px;
    }
  }
}
