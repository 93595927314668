@import "../../../assets/scss/mixin";
.mycars-main {
  width: 100%;
}

.mycars-wrapper {
  width: 70rem;
  h4 {
    font-size: 30px;
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 20px;
    }
  }
  padding: 0px 0px 80px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 0 40px 40px 40px;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
    color: var(--white-color);
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 18px;
      text-align: center;
    }
  }

  .mycars-heading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .mycars-build-new-car-btn {
    width: 150px;
    height: 50px;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 10px;
    border: none;
    font-size: 18px;

    &:hover {
      background-color: var(--theme-second-color);
      border: none;
    }
  }
}

.mycars-type {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 70rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
}
.mycars-cards {
  border: 1.5px solid #c9c9c9;
  border-radius: 10px;
  height: inherit;
  width: 32%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100%;
  }
  img {
    object-fit: cover;
    width: 100%;
    cursor: pointer;
    height: 200px;
    border-radius: 10px 10px 0 0;
    
  }
  .mycars-name {
    font-size: 18px;
    font-weight: 600;
    margin: 10px 0 0 0;
    padding-left: 10px;
  }
  .mycars-intro {
    font-size: 14px;
    font-weight: 400;
    padding-left: 10px;
  }

  .mycars-color {
    font-size: 13px;
    font-weight: 400;
    padding-left: 11px;
    margin: 10px 0 0 0;
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .mycars-divider {
    height: 1px;
    background-color: lightgrey;
    margin: auto;
    width: 99%;
  }

  .mycars-new-from {
    font-size: 14px;
    padding: 10px 0 0 10px;
  }

  .mycars-price {
    font-size: 16px;
    font-weight: 600;
    padding: 0px 0px 0px 10px;
  }

  .mycars-btn-container {
    display: flex;
    justify-content: center;
  }

  .mycars-view-specs-btn {
    width: 95%;
    height: 50px;
    margin: 10px 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 10px;
    border: none;
    font-size: 18px;
  }

  .mycars-view-specs-btn:hover {
    background-color: var(--theme-second-color);
    border: none;
  }
}
