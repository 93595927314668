$dark_color: #272727;
$white_color: #ffffff;
$theme_color: #23503a;
$theme_second_color: #8aab9b;
$theme_third_color: #f7d329;
$w_100: 100%;
$position_rel: relative;
$position_abs: absolute;
$hidden: hidden;
$transparent: transparent;
$flex: flex;
$margin_auto: 0 auto;
$breakpoint_mobile_landscape: 767px;
$breakpoint_mobile_portrait: 600px;
$breakpoint_small_portrait: 320px;
$breakpoint_tablet: 999px;
$breakpoint_mini_laptop: 1200px;
$breakpoint_minix_laptop: 1300px;
$breakpoint_laptop: 1600px;
$d_none: none;
$d_block: block;
