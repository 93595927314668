@import "../../assets/scss/mixin";

.fraud-disclaimer-wrapper {
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 65rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 40px 20px;
  }
}
