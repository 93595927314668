@import "../../assets/scss/mixin";

.about-us-wrapper {
  width: 100%;
}

.about-us-container {
  padding: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 65rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    flex-direction: column;
  }
}

.about-us-container-second {
  padding: 0px 80px 80px 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 65rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    flex-direction: column;
  }
}

.about-us-content {
  width: 50%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }

  p {
    width: 90%;
    @media (max-width: $breakpoint_mini_laptop) {
      width: 100%;
    }
  }
}

.about-us-content-second {
  width: 50%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
  h2 {
    margin-left: 10%;
  }
  p {
    width: 100%;
    margin-left: 10%;
    @media (max-width: $breakpoint_mini_laptop) {
      width: 100%;
      margin-left: 0;
    }
  }
}

.about-us-image {
  width: 50%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 0 100px 0 0;
  }
}

.about-us-image-1 {
  width: 50%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    border-radius: 100px 0 0 0;
  }
}
