@import "../../assets/scss/mixin";

.login-form-wrapper {
  padding: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 65rem;
  margin: auto;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 40px 20px;
  }
  h4 {
    font-size: 30px;
    font-weight: 600;
    margin: 0;
  }

  h3 {
    margin: 0;
  }

  .login-form-container {
    box-shadow: 4px 4px 24px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    padding: 40px 20px;
    margin: 30px 0px;
    width: 500px;
    display: flex;
    flex-direction: column;
    @media (max-width: $breakpoint_tablet) {
      width: 100%;
    }
  }

  .login-field {
    width: 100%;
    border: 1px solid var(--theme-second-color);
    height: 60px;
    border-radius: 10px;
    margin-bottom: 20px;
    border: none;
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin: 0;
    input[type="checkbox"] {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      appearance: none;
      border: 2px solid var(--theme-color);
      border-radius: 5px;
      background-color: white;
      cursor: pointer;

      &:checked {
        background-color: var(--theme-color);
      }
    }
    p {
      font-size: 16px;
      font-weight: 500;
      padding-top: 10px;
      cursor: pointer;
    }
  }

  .login-btn {
    width: 100%;
    height: 50px;
    margin: 20px 0 20px 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 10px;
    border: none;
    font-size: 22px;
    font-weight: bold;
  }
  .login-btn:hover {
    background-color: var(--theme-second-color);
  }

  .delete-btn {
    width: 100%;
    height: 50px;
    margin: 20px 0 20px 0;
    background-color: red;
    color: var(--white-color);
    border-radius: 10px;
    border: none;
    font-size: 22px;
    font-weight: bold;
  }
  .delete-btn:hover {
    background-color: var(--black-color);
  }

  .forgot-password {
    font-size: 18px;
    color: var(--theme-color);
    text-decoration: underline;
    text-align: center;
    cursor: pointer;
  }

  .btn-bottom-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .have-account {
    margin: 20px 0 0 0;
    font-size: 18px;
    a {
      font-size: 18px;
      color: var(--theme-color);
      text-decoration: underline;
      text-align: center;
      cursor: pointer;
    }
  }

  .fp-container {
    display: flex;
    justify-content: space-between;
    a {
      margin-top: 10px;
    }
  }
}
