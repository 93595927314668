@import "../../assets/scss/mixin";
.header-wrapper {
  background: var(--white-color);
  padding: 13px 0;
  top: 0;
  width: 100%;
  left: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  @include transition;
  @media (max-width: $breakpoint_laptop) {
    padding: 10px 0;
  }
  @media (max-width: $breakpoint_tablet) {
    padding-right: 40px !important;
  }
  &.is_sticky {
    padding: 10px 0;
    background: var(--white-color);
    position: fixed;
    z-index: 999;
    .logo {
      max-width: 300px;
      @media (max-width: $breakpoint_laptop) {
        max-width: 250px;
      }
      @media (max-width: $breakpoint_tablet) {
        max-width: 180px;
      }
    }
    .hamburger {
      top: 7px;
    }

    .navigation-wrap {
      .navigation {
        li {
          a {
            color: var(--theme-color);
          }
        }
      }
    }

    .logo-heading {
      color: var(--theme-color);
    }
  }
  .container {
    justify-content: center;
  }
  .logo {
    max-width: 350px;
    @include transition;
    img {
      width: 100%;
    }
    @media (max-width: $breakpoint_laptop) {
      max-width: 280px;
    }
    @media (max-width: $breakpoint_tablet) {
      max-width: 180px;
    }
  }
  .navigation-wrap {
    @media (max-width: $breakpoint_minix_laptop) {
      position: absolute;
      right: 10px;
      top: 70px;
      background: var(--white-color);
      width: 260px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      display: none;
      z-index: 999;
      &.show {
        display: block;
      }
    }
    .navigation {
      @media (max-width: $breakpoint_minix_laptop) {
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 0;
      }
      li {
        margin: 0 20px;
        position: relative;
        @media (max-width: $breakpoint_minix_laptop) {
          margin: 7px 0;
          padding: 0 20px;
          text-align: left;
        }
        &:last-child {
          margin-right: 0;
        }
        a {
          font-size: 18px;
          color: var(--theme-color);
          text-transform: uppercase;
          font-weight: 500;
          @include transition;
          &:hover,
          &.active-link {
            color: var(--theme-second-color);
          }
          @media (max-width: $breakpoint_laptop) {
            font-size: 14px;
            font-weight: bold;
          }
          @media (max-width: $breakpoint_mobile_landscape) {
            color: var(--theme-color);
          }
          @media (max-width: $breakpoint_minix_laptop) {
            font-size: 15px;
          }
        }
      }
    }
  }
  .login-b {
    display: none;
    @media (max-width: $breakpoint_minix_laptop) {
      display: block;
    }
  }
}

.logo-heading {
  font-size: 30px;
  color: var(--white-color);
  font-weight: bold;
  padding: 20px 0px 10px 0px;
  @media (max-width: $breakpoint_minix_laptop) {
    font-size: 20px;
    padding: 0;
  }
}

.login-wrapper {
  @media (max-width: $breakpoint_minix_laptop) {
    &.butn {
      margin-left: 20px;
    }
  }
  .login-buttons {
    display: flex;
    a {
      display: flex;
      background: var(--theme-color);
      color: var(--white-color);
      border: 1px solid var(--theme-color);
      border-radius: 0.3rem;
      margin-left: 20px;
      height: 50px;
      align-items: center;
      justify-content: center;
      min-width: 140px;
      padding: 0 30px;
      @include transition;
      font-size: 16px;
      &:first-child {
        margin-left: 0;
      }
      &:hover {
        border-color: var(--theme-second-color);
        background: var(--theme-second-color);
      }
      &:last-child {
        border-color: var(--theme-color);
        background: var(--theme-color);
        &:hover {
          background: var(--theme-second-color);
          border-color: var(--theme-second-color);
        }
      }
      @media (max-width: $breakpoint_laptop) {
        height: 40px;
        min-width: 110px;
      }
    }
  }
  figure {
    margin: 0;
    position: relative;
    cursor: pointer;
    figcaption {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      width: 200px;
      background: var(--black-color);
      position: absolute;
      right: 0;
      top: 100%;
      display: flex;
      margin-top: 10px;
      flex-direction: column;
      padding: 5px 15px;
      visibility: hidden;
      opacity: 0;
      &.show {
        visibility: visible;
        opacity: 1;
      }
      a {
        padding: 5px 0;
        color: var(--white-color);
        @include transition;
        &:hover {
          color: var(--theme-second-color);
        }
      }
    }
  }
  .header-btn {
    background-color: #e5b021;
    border-radius: 0.3rem;
    font-size: 14px;
    padding: 10px 24px;
    display: inline-block;
    color: #ffffff;
    @include transition;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
}

.hamburger {
  display: none;
  @include transition;
  width: 40px;
  font-size: 20px;
  height: 40px;
  border: 2px solid #fff;
  background: #fff;
  border-radius: 100%;
  color: var(--black-color);

  @media (max-width: $breakpoint_minix_laptop) {
    display: block;
    position: absolute;
    right: 15px;
    top: 7px;
  }

  @media (min-width: $breakpoint_tablet) {
    margin-top: 12px;
  }
}

.userImg {
  display: none;
  @include transition;
  width: 40px;
  font-size: 20px;
  height: 40px;
  border: 2px solid #fff;
  background: transparent;
  background-size: "cover";
  background-position: "center";
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media (max-width: $breakpoint_minix_laptop) {
    display: block;
    position: absolute;
    right: 15px;
    top: 7px;
  }

  @media (min-width: $breakpoint_tablet) {
    margin-top: 12px;
  }
}
.logged-in-side {
  display: flex;
  margin: -5px 0 0;
  gap: 20px;
  @media (max-width: $breakpoint_tablet) {
    gap: 10px;
  }
}
.loggedin-option {
  padding: 0;
  border: none;
  background: transparent;
}
img.header-img {
  width: 40px;
  height: 36px;
  object-fit: contain;
  @media (max-width: $breakpoint_tablet) {
    width: 25px;
    height: 30px;
    margin-right: 13px;
  }
}
.coach-header {
  display: flex;
  padding: 8px 20px 10px;
  background: #ffffff;
  z-index: 999;
  box-shadow: 10px 0 10px rgba(0 0 0 / 6%);
}

.animated-menu {
  animation-name: menuAnimation;
  animation-duration: 5s;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 999;
}

.animated-menu img {
  max-width: 100%;
  max-height: 100%;
}

@keyframes menuAnimation {
  0% {
    opacity: 0;
    transform: translateY(0%);
  }

  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
.custom-dropdown {
  position: relative;
  display: inline-block;
  .selected-language {
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    img {
      width: 40px;
      height: 40px;
    }
  }

  .dropdown-options {
    position: absolute;
    top: 55px;
    left: -107px;
    width: 150px;
    background-color: #fff;
    border-top: none;
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    z-index: 1;

    &::before {
      bottom: 100%;
      right: 2.5%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
      border-color: rgba(194, 225, 245, 0);
      border-bottom-color: #f5f5f5;
      border-width: 10px;
    }

    &::after {
      bottom: 100%;
      right: 2.5%;
      border: solid transparent;
      content: "";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    .option {
      padding: 8px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 10px;
      span {
        margin-right: 8px;
        font-size: 14px;
        font-weight: 500;
        color: var(--theme-color);
        &:hover {
          color: var(--theme-second-color);
        }
      }
    }
  }
}
