@import "../../../assets/scss/mixin";

.services-wrapper {
  padding: 0px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  // border-radius: 200px;
  background-color: var(--theme-color);
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 0 40px 40px 40px;
  }
  h4 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
    color: var(--white-color);
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 20px;
    }
  }
  .sub-heading {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
    color: var(--white-color);
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 18px;
      text-align: center;
    }
  }
}
.services-card {
  display: flex;
  gap: 20px;
  width: 65rem;
  justify-content: space-between;
  @media (max-width: $breakpoint_mini_laptop) {
    flex-direction: column;
    width: 100%;
  }
}
.card-container {
  padding: 20px;
  border: 1px solid var(--theme-second-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 33%;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
  p {
    font-size: 20px;
    font-weight: 600;
    color: var(--white-color);
  }
  span {
    font-size: 16px;
    font-weight: 400;
    color: var(--white-color);
    text-align: center;
  }
}

.card-container:hover {
  border: 1px solid var(--theme-color);
  background-color: var(--theme-second-color);
}

.fa-handshake-o,
.fa-file-text,
.fa-check {
  padding: 0px 0px 10px 0px;
  font-size: 40px;
  color: var(--white-color);
}
