@import './_reset.scss';
h1 { font-size: 26px;}
h2 { font-size: 24px;}
h3 { font-size: 22px;}
h4 { font-size: 20px;}
h5 { font-size: 18px;}
h6 { font-size: 16px;}
h1,h2,h3,h4,h5,h6 {
  a {
    color: var(--black-color);
  }
}
p {
  line-height: 25px;
  margin: 0 0 10px;
  a {
    color: var(--black-color);
  }
}
a {
  color: var(--black-color);
  text-decoration: none;
}
a {
  &:hover, &:focus, &:active{
    outline: none;
    text-decoration: none;
  }
}
.section-title {
  margin-bottom: 60px;
  text-align: center;
  @media (max-width: $breakpoint_laptop) {
    margin-bottom: 40px;
  }
  span {
    font-weight: 200;
    font-size: 28px;
    display: block;
    letter-spacing: 5px;
    @media (max-width: $breakpoint_laptop) {
      font-size: 20px;
      letter-spacing: 3px;
    }
  }
  h2 {
    font-size: 60px;
    margin: 0 0 20px;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      width: 100px;
      height: 2px;
      background-color: var(--theme-color);
    }
    @media (max-width: $breakpoint_laptop) {
      font-size: 45px;
      margin-bottom: 15px;
    }
    @media (max-width: $breakpoint_tablet) {
      font-size: 35px;
    }
  }
  p {
    margin: 0 auto 20px;
    width: 65%;
    @media (max-width: $breakpoint_tablet) {
      width: 100%;
    }
  }
}
html {
  scroll-behavior: smooth;
}
.loader {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 50px;
  }
}
.loader-btn {
  &:after {
    content: '';
    display: flex;
    width: 50px;
    height: 24px;
    background: url("../images/loader1.gif") no-repeat;
    margin: 0 -25px 0 5px;
    background-size: contain;
  }
}

.table-loader-btn {
  &:after {
    content: '';
    display: flex;
    width: 40px;
    height: 20px;
    background: url("../images/loader1.gif") no-repeat;
    margin: 0 -25px 0 5px;
    background-size: contain;
  }
}

.disabled {
  background: #dddddd !important;
  color: #555555 !important;
  cursor: not-allowed;
  pointer-events: none;
  border-color: #dddddd !important;
}
.typography {
  button { margin: 0 10px 20px;}
}
.input-form-wrap {
  ul{
    margin: 0 -10px;
    li {
      width: 50%;
      padding: 0 10px;
      .banner-btn { height: 54px;}
    }
  }
}