@import "../../../assets/scss/mixin";
.work-main {
  width: 100%;
  background-color: var(--theme-color);
}

.work-wrapper {
  padding: 0px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--white-color);
  border-radius: 0px 0px 200px 0px;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 0px 40px 40px 40px;
  }
  h4 {
    text-align: center;
    font-size: 30px;
    margin-bottom: 10px;
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 20px;
    }
  }
}
.work-container {
  display: flex;
  width: 65rem;
  justify-content: space-between;
  align-items: center;
  @media (max-width: $breakpoint_mini_laptop) {
    flex-direction: column;
    width: 100%;
  }
}
.work-card {
  padding: 20px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
  .heading-container {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  h1 {
    margin: 0px;
    font-size: 100px;
  }
  p {
    font-size: 20px;
    font-weight: 600;
  }
  span {
    text-align: center;
  }
}

.fa-book {
  font-size: 100px;
  color: var(--theme-color);
}

.fa-long-arrow-right {
  font-size: 100px;
  @media (max-width: $breakpoint_mini_laptop) {
    display: none;
  }
}
