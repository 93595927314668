@import "../../../assets/scss/mixin";
.recommended-main {
  width: 100%;
  background-color: var(--theme-color);
}

.recommended-wrapper {
  h4 {
    font-size: 30px;
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 20px;
    }
  }
  padding: 0px 80px 80px 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  background-color: var(--white-color);
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
    padding: 0 40px 40px 40px;
  }

  .sub-heading {
    font-size: 20px;
    font-weight: 400;
    padding-bottom: 20px;
    color: var(--white-color);
    @media (max-width: $breakpoint_mini_laptop) {
      font-size: 18px;
      text-align: center;
    }
  }
}

.car-type {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  width: 65rem;
  @media (max-width: $breakpoint_mini_laptop) {
    width: 100%;
  }
}
.car-cards {
  border: 1px solid var(--theme-color);
  border-radius: 10px;
  width: 23.5%;
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $breakpoint_mobile_portrait) {
    width: 100%;
  }
  img {
    object-fit: cover;
    // width: 100%;
    cursor: pointer;
    // height: 200px;
    border-radius: 10px 10px 0 0;
  }
  .car-name {
    font-size: 20px;
    font-weight: 600;
    margin: 30px 0 0 0;
    padding-left: 10px;
  }
  .car-intro {
    font-size: 16px;
    font-weight: 400;
    padding-left: 10px;
  }

  .divider {
    height: 1px;
    background-color: lightgrey;
    margin: auto;
    width: 99%;
  }

  .new-from {
    font-size: 14px;
    padding: 10px 0 0 10px;
  }

  .price {
    font-size: 16px;
    font-weight: 600;
    padding: 0px 0px 10px 10px;
  }

  .btn-container {
    display: flex;
    justify-content: center;
  }

  .view-specs-btn {
    width: 90%;
    height: 50px;
    margin: 10px 0;
    background-color: var(--theme-color);
    color: var(--white-color);
    border-radius: 10px;
    border: none;
  }

  .view-specs-btn:hover {
    background-color: var(--theme-second-color);
    border: none;
  }
}
